import { LinearProgress, Typography } from "@mui/material";
import clsx from "clsx";
import { ErrorBox } from "components/error";
import TableSet from "components/tableSetExplorer/TableSet";
import { useSolutionKey } from "features/solution";
import { isEmpty } from "lodash";
import { Fragment } from "react";
import CalculationNotExist from "./CalculationNotExist";
import UnCompletedCalculationState from "./UnCompletedCalculationState";
import { useStyles } from "./calculationResultStyle";
import { extractCalculationData } from "./calculationResultUtils";
import {
  useCalculationInputData,
  useCalculationOutputData,
  useQueryCalculationSchema
} from "./hooks/calculationResultHooks";

const CalculationResult = ({
  useCaseKey,
  useCaseVersion,
  calculationId
}: {
  useCaseKey: string;
  useCaseVersion: string;
  calculationId: string;
}) => {
  const {
    root,
    tableSetWrapper,
    margin,
    centered,
    leftBackgroundColor,
    rightBackgroundColor,
    marginLeft
  } = useStyles({});
  const solutionKey = useSolutionKey();
  const { data, loading, error } = useQueryCalculationSchema({
    solutionKey,
    useCaseKey,
    useCaseVersion,
    calculationId
  });

  const calculation =
    data?.solutions.solution?.useCases?.useCases?.[0]?.calculation;

  const { loadOutputData } = useCalculationOutputData();
  const { loadInputData } = useCalculationInputData();

  if (error)
    return (
      <ErrorBox apolloError={error} title="Unable to access this calculation" />
    );
  if (loading)
    return (
      <LinearProgress
        className={margin}
        data-testid={loading ? "progress-loading" : "progress-done"}
      />
    );
  if (!calculation) {
    return <CalculationNotExist />;
  }
  const {
    state,
    inputSchema,
    outputSchema,
    inputColumnDefs,
    outputColumnDefs,
    calculationError
  } = extractCalculationData(calculation);

  return (
    <div className={root} key={calculationId}>
      <div className={tableSetWrapper}>
        {isEmpty(inputSchema) ? (
          <div className={centered}>
            <Typography>No input data required.</Typography>
          </div>
        ) : (
          <TableSet
            //@ts-ignore
            tablesSchema={inputSchema}
            calculationId={calculationId}
            useCaseVersion={useCaseVersion}
            useCaseKey={useCaseKey}
            loadData={loadInputData}
            columnDefs={inputColumnDefs}
            toCalculationResultTable={(name: string) => `./input-data/${name}`}
            backgroundColor={leftBackgroundColor}
          />
        )}
      </div>

      <div className={clsx(tableSetWrapper, marginLeft)}>
        {state && state !== "Completed" ? (
          <UnCompletedCalculationState
            state={state}
            calculationError={calculationError}
          />
        ) : (
          <Fragment>
            {isEmpty(outputSchema) ? (
              <div className={centered}>
                <Typography>
                  This web service does not return any data.
                </Typography>
              </div>
            ) : (
              <TableSet
                //@ts-ignore
                tablesSchema={outputSchema}
                calculationId={calculationId}
                useCaseKey={useCaseKey}
                useCaseVersion={useCaseVersion}
                loadData={loadOutputData}
                columnDefs={outputColumnDefs}
                toCalculationResultTable={(name: string) =>
                  `./output-data/${name}`
                }
                backgroundColor={rightBackgroundColor}
              />
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};
export default CalculationResult;
