import {
  ApolloError,
  MutationHookOptions,
  gql,
  useApolloClient,
  useMutation,
  useQuery
} from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { DataCount, DataCountVariables } from "./schema/DataCount";
import {
  ScheduleCalculation,
  ScheduleCalculationVariables
} from "./schema/ScheduleCalculation";
import {
  UseCaseData,
  UseCaseDataVariables,
  UseCaseData_solutions_solution_useCases_useCase_data
} from "./schema/UseCaseData";

const query = gql`
  query UseCaseData(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $skipUntilAddress: String
    $limit: Int
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            data(skipUntilAddress: $skipUntilAddress, limit: $limit) {
              address
              detail {
                metaData {
              creator {
                firstName
                lastName
                name
              }
                  displayName
                }
                key
                lastModified
                size
                bucketName
              }
            }
          }
        }
      }
    }
  }
`;

const dataCountQuery = gql`
  query DataCount(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            dataCount
          }
        }
      }
    }
  }
`;
export const useQueryUseCaseData = (variables: UseCaseDataVariables) =>
  useQuery<UseCaseData, UseCaseDataVariables>(query, {
    variables
  });

export const useQueryUseCaseDataCount = (variables: DataCountVariables) =>
  useQuery<DataCount, DataCountVariables>(dataCountQuery, { variables });
const mutation = gql`
  mutation ScheduleCalculation(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $dataSourceAddress: String!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            scheduleCalculation(dataSourceAddress: $dataSourceAddress)
          }
        }
      }
    }
  }
`;

export const useMutationScheduleCalculation = (
  options?: MutationHookOptions<
    ScheduleCalculation,
    ScheduleCalculationVariables
  >
) =>
  useMutation<ScheduleCalculation, ScheduleCalculationVariables>(
    mutation,
    options
  );

export const useQueryLoadUseCaseData = (
  solutionKey: string,
  useCaseKey: string,
  useCaseVersion: string,
  skipUntilAddress: string | null,
  limit: number
) => {
  const client = useApolloClient();
  const [loadingData, setLoadingData] = useState<boolean>(true);

  const [allData, setAllData] = useState<
    UseCaseData_solutions_solution_useCases_useCase_data[] | null
  >(null);
  const [progressValue, setProgressValue] = useState<number>(0);
  const [errors, setErrors] = useState<ApolloError[]>([]);

  const loadUseCaseData = useCallback(
    async (variables: UseCaseDataVariables) => {
      let { skipUntilAddress, limit, ...rest } = variables;
      try {
        let loaded = 0;
        do {
          const { errors, data } = await client.query<
            UseCaseData,
            UseCaseDataVariables
          >({
            query,
            variables: {
              skipUntilAddress,
              limit,
              ...rest
            },

            errorPolicy: "all"
          });
          if (errors) {
            setErrors(previousError => [
              ...previousError,
              new ApolloError({ graphQLErrors: errors })
            ]);
          }
          const useCase = data.solutions.solution?.useCases?.useCase;
          if (!useCase) {
            setAllData(null);
          }

          const useCaseData = data.solutions.solution?.useCases?.useCase?.data;
          loaded = useCaseData?.length || 0;
          if (Array.isArray(useCaseData) && useCaseData.length > 0) {
            skipUntilAddress = useCaseData.slice(-1)[0]?.address;
            setAllData(d =>
              d === null ? [...useCaseData] : [...d, ...useCaseData]
            );
            setProgressValue(value => value + useCaseData.length);
          }
        } while (loaded === limit);
        setLoadingData(false);
      } catch (error) {
        setLoadingData(false);
        setErrors(previousError => [error as ApolloError, ...previousError]);
      }
    },
    [client]
  );
  useEffect(() => {
    const getData = async () => {
      await loadUseCaseData({
        solutionKey,
        useCaseKey,
        useCaseVersion,
        skipUntilAddress,
        limit
      });
    };
    getData();
  }, [
    solutionKey,
    loadUseCaseData,
    useCaseKey,
    useCaseVersion,
    skipUntilAddress,
    limit
  ]);
  return { allData, loadingData, progressValue, errors };
};
