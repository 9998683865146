import {
  Box,
  Theme,
  Tooltip,
  TooltipProps,
  Typography,
  useTheme
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import React, { HTMLAttributes } from "react";

const NoTooltip: React.FC<TooltipProps> = ({ children }) => <>{children}</>;

const useStyles = makeStyles(() =>
  createStyles({
    boldText: { fontWeight: "bold", fontSize: "0.8rem" },
    boxWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center"
    },
    box: {
      borderStyle: "solid",
      borderWidth: 1,
      height: 71,
      width: 72,
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  })
);

type Color = "primary" | "secondary" | "yellow" | "white";

type LegendProps = {
  title: string;
  className?: HTMLAttributes<HTMLDivElement>["className"];
  color: Color;
  children: React.ReactNode;
  tooltip?: React.ReactNode;
};

function getColorValues(color: Color, theme: Theme): [string, string] {
  switch (color) {
    case "primary":
      return [theme.palette.primary.main, theme.palette.primary.main];
    case "secondary":
      return [theme.palette.secondary.main, theme.palette.secondary.main];
    case "yellow":
      return ["#f1d797", "#f1d797"];
    case "white":
      return [theme.palette.common.white, theme.palette.common.black];
  }
}

export default function CapacityLegend({
  title,
  className,
  color,
  children,
  tooltip
}: LegendProps) {
  const classNames = useStyles();
  const theme = useTheme();

  const [bgColor, borderColor] = getColorValues(color, theme);
  const MaterialTooltip = tooltip ? Tooltip : NoTooltip;
  return (
    <MaterialTooltip title={tooltip}>
      <div className={classNames.boxWrapper}>
        <Box
          bgcolor={bgColor}
          borderColor={borderColor}
          className={clsx(className, classNames.box)}
        >
          <Typography className={classNames.boldText}> {children}</Typography>
        </Box>
        <Typography className={classNames.boldText}>{title}</Typography>
      </div>
    </MaterialTooltip>
  );
}
