import { gql, useMutation } from "@apollo/client";
import { ErrorBox, ErrorMessage } from "components/error";
import { useSolutionKey } from "features/solution";
import { notNullOrUndefined } from "utils/typescript";
import Sessions from "./Sessions";
import { ExpireSession, ExpireSessionVariables } from "./schema/ExpireSession";
import { sessionsList, useSessionsDataWithPolling } from "./sessionsHooks";

const mutationExpireSession = gql`
  mutation ExpireSession(
    $solutionKey: Key!
    $projectId: ID!
    $userName: String!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          project(projectId: $projectId) {
            expireUserSession(userName: $userName)
          }
        }
      }
    }
  }
`;

export default function SessionsWrapper({
  projectId,
  canExpireSession
}: {
  projectId: string;
  canExpireSession: boolean;
}) {
  const solutionKey = useSolutionKey();
  const {
    data,
    loading: sessionsDataPolling,
    error: sessionsError
  } = useSessionsDataWithPolling({
    solutionKey,
    projectId
  });

  const [
    expireSession,
    { loading: expireSessionLoading, error: expireSessionError, reset }
  ] = useMutation<ExpireSession, ExpireSessionVariables>(
    mutationExpireSession,
    {
      refetchQueries: [
        { query: sessionsList, variables: { solutionKey, projectId } }
      ]
    }
  );

  const project = data?.solutions.solution?.projects?.project;
  if (data && !project) {
    return <ErrorMessage message={`No project found for id '${projectId}'.`} />;
  }
  var errors = [sessionsError, expireSessionError].filter(notNullOrUndefined);

  if (errors.length > 0)
    return (
      <ErrorBox
        apolloError={errors}
        onClose={reset}
        closable={!sessionsError}
      />
    );

  return (
    <Sessions
      sessions={project?.streamingSessions}
      canExpireSession={canExpireSession}
      expireSession={userName => {
        if (canExpireSession) {
          expireSession({ variables: { solutionKey, userName, projectId } });
        }
      }}
      loading={sessionsDataPolling || expireSessionLoading}
    />
  );
}
