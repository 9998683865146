import { difference } from "lodash";
import { AtLeastOnePermission } from "./RestrictedRealm";

export function missingPermissions<TPermission>(
  requestedPermissions: AtLeastOnePermission<TPermission>,
  memberPermissions: (TPermission | null)[]
) {
  const missingPermissions =
    memberPermissions !== null && memberPermissions
      ? difference(requestedPermissions, memberPermissions)
      : undefined;
  const accessGranted = missingPermissions?.length === 0;

  const data = {
    missingPermissions,
    accessGranted
  };
  return {
    data
  };
}
