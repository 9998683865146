import { ApolloProvider } from "@apollo/client";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import CssBaseline from "@mui/material/CssBaseline";
import {
  StyledEngineProvider,
  Theme,
  ThemeProvider
} from "@mui/material/styles";
import { Shell, authenticatorSetup } from "features/shell";
import { createBrowserHistory } from "history";
import "index.css";
import { Fragment } from "react";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import * as serviceWorker from "serviceWorker";
import "typeface-roboto";
import { assertConfigSetup, printConfigSetup } from "utils/config";
import theme from "utils/theme";
import clientFactory from "./utils/graphql/clientFactory";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// todo: LPS-7379 make this a ts file and check typings

printConfigSetup();
assertConfigSetup();
const history = createBrowserHistory();
authenticatorSetup(history);

const client = clientFactory();

ReactDOM.render(
  <Router history={history}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Authenticator.Provider>
          <ApolloProvider client={client}>
            <Fragment>
              <CssBaseline />
              <Shell />
            </Fragment>
          </ApolloProvider>
        </Authenticator.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </Router>,

  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
