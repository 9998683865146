import CardWithRoles from "components/card/CardWithRoles";
import workspacesImage from "./workspaces.svg";

export default function WorkspacesCard() {
  return (
    <CardWithRoles
      name="Workspaces"
      image={workspacesImage}
      description="Identify solution members currently working on a workspace, see which projects share a common workspace and configure available working seats."
      targetRoles={["Project leader"]}
      link={`/workspaces`}
      testid="workspaces-button"
    />
  );
}
