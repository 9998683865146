import { Grid, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Dispatch, FormEvent } from "react";
import MemberGroups from "./MemberGroups";
import { useQueryGetAvailableGroups } from "./hooks/membersHooks";
import { Action, State } from "./hooks/useInviteSolutionMemberReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      "&.MuiInput-underline:after": {
        borderBottomColor: "#3AA9AE"
      },
      "&.MuiOutlinedInput-root": {
        "&:hover fieldset": {
          borderColor: "rgba(0, 0, 0, 0.23)"
        },
        "&.Mui-focused fieldset": {
          borderColor: "#3AA9AE"
        }
      }
    },
    select: {
      margin: theme.spacing(2, 0, 1, 0)
    }
  })
);

interface InviteMemberFormProps {
  solutionKey: string;
  state: State;
  dispatch: Dispatch<Action>;
  onSubmit: (event: FormEvent) => void;
}

export default function InviteMemberForm({
  solutionKey,
  state,
  dispatch,
  onSubmit
}: InviteMemberFormProps) {
  const classes = useStyles();
  const { data, loading: loadingGroups } =
    useQueryGetAvailableGroups(solutionKey);
  const groupNames =
    data?.solutions.solution?.access?.availableGroups.map(
      group => group.name
    ) ?? [];

  return (
    <form id="memberForm" onSubmit={onSubmit}>
      <Grid container direction="column">
        <TextField
          id="email"
          label="Email"
          name="email"
          inputProps={{
            maxLength: 256,
            "data-testid": "email"
          }}
          required
          value={state.email}
          onChange={e => {
            const value = e.target.value;
            dispatch({ type: "SET_EMAIL", value });
          }}
          margin="normal"
          variant="outlined"
          type="email"
          className={classes.textField}
        />

        <Grid item className={classes.select}>
          <MemberGroups
            memberGroups={state.groups}
            userName={state.email}
            allGroups={groupNames}
            loadingGroups={loadingGroups}
            onMemberGroupUpdated={(_, groups) => {
              dispatch({
                type: "UPDATE_GROUPS",
                value: groups
              });
            }}
            label="Groups"
            height={56}
          />
        </Grid>
      </Grid>
    </form>
  );
}
