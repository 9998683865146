import { SearchRounded } from "@mui/icons-material";
import { Grid, InputBase, LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { ErrorBox } from "components/error";
import { ActionBarContent } from "features/shell";
import { useSolutionKey } from "features/solution";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { createRoute, useSearchQueryParam } from "utils/url";
import UseCaseCard from "./UseCaseCard";
import useQueryUseCaseList from "./hooks/useQueryUseCaseList";

const useStyles = makeStyles(theme => ({
  searchBar: {
    width: 300,
    backgroundColor: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(1)}`,
    borderBottom: "solid 1px rgba(0, 0, 0, 0.12)"
  }
}));

export default function UseCasesTileView() {
  const solutionKey = useSolutionKey();
  const classNames = useStyles();
  const {
    data,
    loading,
    error: useCasesError
  } = useQueryUseCaseList(solutionKey);
  const [searchValue, setSearchValue] = useSearchQueryParam("search");
  const searchInputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    searchInputRef.current?.focus();
  }, []);

  const useCases = useMemo(() => {
    if (!data) return [];
    let result = data.solutions.solution?.useCases?.useCases;
    if (!result) return [];
    if (!isEmpty(searchValue)) {
      result = result.filter(useCase => {
        return `${useCase.detail.name} ${useCase.detail.tags.join(" ")}`
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());
      });
    }

    return result.sort((a, b) => a.detail.name.localeCompare(b.detail.name));
  }, [data, searchValue]);
  const history = useHistory();
  if (loading) return <LinearProgress />;

  if (useCasesError)
    return (
      <ErrorBox
        title="An error occurred while loading the web services"
        apolloError={useCasesError}
        onClose={() => {
          history.push(createRoute(`${process.env.PUBLIC_URL}/`));
        }}
        closable
      />
    );
  return (
    <>
      <ActionBarContent>
        <div className={classNames.searchBar}>
          <InputBase
            placeholder="Search a web service"
            value={searchValue}
            onChange={e => setSearchValue(e.target.value)}
            inputRef={searchInputRef}
          />
          <SearchRounded color="secondary" />
        </div>
      </ActionBarContent>
      <Grid container justifyContent="center" spacing={4}>
        {useCases.map(({ detail: { key, ...useCaseDetail } }) => (
          <UseCaseCard useCaseKey={key} {...useCaseDetail} key={key} />
        ))}
      </Grid>
    </>
  );
}
