import { useSolutionKey } from "features/solution";
import { useQueryUseCaseData } from "./topics/calculate/calculationHooks";

export default function UploadedDataName({
  useCaseKey,
  useCaseVersion = null,
  dataId
}: {
  useCaseKey: string;
  useCaseVersion: string | null;
  dataId?: string;
}) {
  const solutionKey = useSolutionKey();
  const { data: useCaseData } = useQueryUseCaseData({
    solutionKey,
    useCaseKey,
    useCaseVersion
  });
  const currentData =
    useCaseData?.solutions.solution?.useCases?.useCase?.data.filter(
      d => d.detail?.key.split("/").slice(-1)[0] === dataId
    )[0];
  return <div>{currentData?.detail?.metaData.displayName}</div>;
}
