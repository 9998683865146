import {
  AtLeastOnePermission,
  WorkspacePermissions,
  missingPermissions
} from "features/access-management";
import useWorkspacePermissions from "./useWorkspacePermissions";

export const useRequestUserPublicApiPermissions = (
  requestedPermissions: AtLeastOnePermission<WorkspacePermissions>
) => {
  const { data: permissionsData, ...queryResult } = useWorkspacePermissions();
  const myPermissions = permissionsData?.workspaces.myPermissions || [];
  const myMissingPermissions = missingPermissions(
    requestedPermissions,
    myPermissions
  );
  return {
    ...queryResult,
    ...myMissingPermissions
  };
};
