import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Topic } from "features/use-case";

const useStyles = makeStyles(theme => ({
  topicColor: {
    backgroundColor: theme.palette.primary.dark
  },
  divider: {
    height: 32,
    borderLeft: "solid 2px #ffffff"
  },
  fontSize: { fontSize: 60 },
  badge: { fontSize: 12, marginBottom: -10 },
  flex: { display: "flex", alignItems: "center" },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    marginRight: theme.spacing(3)
  },
  available: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(1)
  }
}));

type WorkspaceCardProps = {
  workspaceId: string;
  name: string;
  loggedInUsers?: number;
  available?: number;
};

export default function WorkspaceCard({
  workspaceId,
  name,
  loggedInUsers,
  available
}: WorkspaceCardProps) {
  const classNames = useStyles();
  return (
    <Topic
      title={name}
      icon={null}
      dataTest="topic-show-workspace"
      to={`/workspaces/${workspaceId}`}
      colorClassName={classNames.topicColor}
      toolTipTitle="Workspace"
    >
      <div className={classNames.flex}>
        <div className={classNames.flexColumn}>
          <Typography className={classNames.badge}>Taken seats</Typography>
          <Typography className={classNames.fontSize}>
            {loggedInUsers}
          </Typography>
        </div>
        <div className={classNames.divider}></div>
        <div className={classNames.available}>
          <Typography className={classNames.badge}>Free seats</Typography>
          <Typography className={classNames.fontSize}>{available}</Typography>
        </div>
      </div>
    </Topic>
  );
}
