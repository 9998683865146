import { ApolloError } from "@apollo/client";
import { LinearProgress } from "@mui/material";
import { ErrorBox } from "components/error";
import AccessDenied from "./AccessDenied";
import RestrictedRealmDebugger from "./RestrictedRealmDebugger";

export interface BasicRestrictedRealmProps<TPermission> {
  /** Permissions required to access the content (children of this component) */
  requiredPermissions: AtLeastOnePermission<TPermission>;
  /** If silent the component does display either the children (permissions available) or nothing.
   *  No loading indicator, missing permissions or fallback will be shown. */
  silent?: boolean;

  /** The content that requires the permission to be accessed. */
  children: React.ReactNode | RenderFunc;

  /** By default a access denied component is shown reporting the missing permissions.  */
  fallback?: React.ReactNode;
}

interface RestrictedRealmProps<TPermission>
  extends BasicRestrictedRealmProps<TPermission> {
  missingPermissions: (TPermission | null)[] | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  accessGranted: boolean;
  errorTitle: string;
}

interface RenderProps {
  accessGranted: boolean;
}

type RenderFunc = (props: RenderProps) => JSX.Element;
export type AtLeastOnePermission<TPermission> = [TPermission, ...TPermission[]];

/**
 * Component to specify access rights for a react subtree.
 * 
 * @example
 * Can be used in different ways:
 * 
 *  | Props       | Access check |   Access granted | Access denied
 *  | (default)   | indicator    | displays content | access denied
 *  | silent      | nothing      | displays content | nothing
 *  | fallback    | indicator    | displays content | fallback

 */
const RestrictedRealm = <TPermission extends {}>({
  requiredPermissions,
  silent = false,
  children,
  fallback,
  loading,
  error,
  accessGranted,
  missingPermissions,
  errorTitle
}: RestrictedRealmProps<TPermission>) => {
  if (error) return <ErrorBox apolloError={error} title={errorTitle} />;
  return (
    <RestrictedRealmDebugger<TPermission>
      requiredPermissions={requiredPermissions}
      accessGranted={accessGranted}
      missingPermissions={missingPermissions}
    >
      {loading ? (
        <LinearProgress />
      ) : typeof children === "function" ? (
        children({ accessGranted })
      ) : (
        <>
          {accessGranted ? (
            <>{children}</>
          ) : !silent ? (
            <>
              {fallback !== undefined
                ? fallback
                : missingPermissions && (
                    <AccessDenied missingPermissions={missingPermissions} />
                  )}
            </>
          ) : null}
        </>
      )}
    </RestrictedRealmDebugger>
  );
};
export default RestrictedRealm;
