import { LinearProgress } from "@mui/material";

import { TagBox } from "devextreme-react";
import { difference } from "lodash";
import { useState } from "react";
import { useStyles } from "./MemberGroupsStyle";

type MemberGroupProps = {
  memberGroups?: string[];
  userName: string;
  allGroups?: string[];
  onMemberGroupUpdated: (userName: string, group: [string]) => void;
  loadingGroups: boolean;
  label?: string;
  height?: number;
};

export default function MemberGroups({
  memberGroups,
  userName,
  allGroups = [],
  onMemberGroupUpdated,
  loadingGroups,
  label,
  height
}: MemberGroupProps) {
  const classes = useStyles();

  const [isFocused, setIsFocused] = useState(false);
  if (!memberGroups) return null;

  const handleChange = (options: any) => {
    const value = options.value;
    const previousValue = options.previousValue;
    const addedGroups = difference(value, previousValue);
    if (addedGroups[0]) {
      onMemberGroupUpdated(userName, value);
    } else {
      const deletedGroups = difference(previousValue, value);
      if (deletedGroups[0])
        onMemberGroupUpdated(
          userName,
          previousValue.filter((v: string) => v !== deletedGroups[0])
        );
    }
  };

  return (
    <div className={classes.cell} data-testid="select-group">
      {loadingGroups ? <LinearProgress data-testid="loading-groups" /> : null}
      <TagBox
        items={allGroups}
        value={memberGroups}
        multiline={true}
        onValueChanged={handleChange}
        placeholder={undefined}
        showDropDownButton
        focusStateEnabled={true}
        onFocusIn={() => setIsFocused(true)}
        onFocusOut={() => setIsFocused(false)}
        width={"100%"}
        label={label}
        labelMode="floating"
        style={{
          height: height ? height : "100%",
          border: isFocused
            ? "solid 2px #3AA9AE"
            : "solid 1px rgba(0, 0, 0, 0.23)"
        }}
        noDataText="no groups"
        className={classes.root}
        elementAttr={{}}
      />
    </div>
  );
}
