import { gql, useQuery } from "@apollo/client";
import {
  QueryProjectById,
  QueryProjectByIdVariables,
  QueryProjectById_solutions_solution_projects_project
} from "./schema/QueryProjectById";

export type Project = QueryProjectById_solutions_solution_projects_project;

export const queryProjectById = gql`
  query QueryProjectById($solutionKey: Key!, $projectId: ID!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          project(projectId: $projectId) {
            databaseName
            description
            error
            id
            image
            name
            state
            tags
            databaseTemplateId
            databaseProgress
            workspaceId
            workspaceName
            isAvailable
          }
        }
      }
    }
  }
`;

export default function useQueryProjectById(
  variables: QueryProjectByIdVariables
) {
  return useQuery<QueryProjectById, QueryProjectByIdVariables>(
    queryProjectById,
    {
      variables,
      notifyOnNetworkStatusChange: true
    }
  );
}
