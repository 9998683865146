import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useCallback, useEffect } from "react";
import {
  QueryCalculationResultInputData,
  QueryCalculationResultInputDataVariables
} from "./schema/QueryCalculationResultInputData";
import {
  QueryCalculationResultOutputData,
  QueryCalculationResultOutputDataVariables
} from "./schema/QueryCalculationResultOutputData";
import {
  QueryCalculationSchema,
  QueryCalculationSchemaVariables
} from "./schema/QueryCalculationSchema";

export const schemaQuery = gql`
  query QueryCalculationSchema(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $calculationId: ID!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculation(calculationId: $calculationId) {
              detail {
                state
                error
              }
              inputData {
                tableSet {
                  tables {
                    schema {
                      displayName
                      name
                      columns {
                        displayName
                        name
                        nullable
                        typeName
                      }
                    }
                  }
                }
              }
              resultData {
                tableSet {
                  tables {
                    schema {
                      displayName
                      name
                      columns {
                        displayName
                        name
                        nullable
                        typeName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useQueryCalculationSchema = (
  variables: QueryCalculationSchemaVariables
) => {
  const { data, loading, startPolling, stopPolling, error } =
    useQuery<QueryCalculationSchema>(schemaQuery, {
      variables,
      pollInterval: 5000
    });
  const state =
    data?.solutions.solution?.useCases?.useCases?.[0]?.calculation?.detail
      .state;
  useEffect(() => {
    if (state === "InProgress" || state === "Scheduled") {
      startPolling(5000);
    } else {
      stopPolling();
    }
  }, [state, startPolling, stopPolling]);
  return { data, loading, error };
};

export const inputDataQuery = gql`
  query QueryCalculationResultInputData(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $calculationId: ID!
    $names: [String]
    $skip: Int
    $take: Int
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculation(calculationId: $calculationId) {
              inputData {
                tableSet {
                  tables(names: $names) {
                    rows(skip: $skip, take: $take)
                    rowCount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const useCalculationInputData = () => {
  const client = useApolloClient();
  const loadInputData = useCallback(
    async (variables: QueryCalculationResultInputDataVariables) => {
      const result = await client.query<
        QueryCalculationResultInputData,
        QueryCalculationResultInputDataVariables
      >({
        query: inputDataQuery,
        variables
      });
      if (!result) {
        return null;
      }
      if (result.errors) {
        throw new Error(result.errors.toString());
      }
      const useCases = result.data.solutions.solution?.useCases?.useCases;
      return useCases?.[0]?.calculation?.inputData.tableSet.tables[0];
    },
    [client]
  );

  return { loadInputData };
};

export const resultDataQuery = gql`
  query QueryCalculationResultOutputData(
    $solutionKey: Key!
    $useCaseKey: Key
    $useCaseVersion: String
    $calculationId: ID!
    $names: [String]
    $skip: Int
    $take: Int
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            calculation(calculationId: $calculationId) {
              resultData {
                tableSet {
                  tables(names: $names) {
                    rows(skip: $skip, take: $take)
                    rowCount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const useCalculationOutputData = () => {
  const client = useApolloClient();

  const loadOutputData = useCallback(
    async (variables: QueryCalculationResultOutputDataVariables) => {
      const result = await client.query<
        QueryCalculationResultOutputData,
        QueryCalculationResultOutputDataVariables
      >({
        query: resultDataQuery,
        variables
      });
      if (!result) {
        return null;
      }
      if (result.errors) {
        throw new Error(result.errors.toString());
      }
      const useCases = result.data.solutions.solution?.useCases?.useCases;

      return useCases?.[0]?.calculation?.resultData?.tableSet.tables[0];
    },
    [client]
  );

  return { loadOutputData };
};
