import { gql, useApolloClient } from "@apollo/client";
import {
  QueryApiDocumentation,
  QueryApiDocumentationVariables
} from "./schema/QueryApiDocumentation";

export const apiDocumentationQuery = gql`
  query QueryApiDocumentation(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
    $apiKeyId: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            publicApi {
              apiDocumentation(apiKeyId: $apiKeyId)
            }
          }
        }
      }
    }
  }
`;

export const useQueryApiDocumentation = () => {
  const client = useApolloClient();
  return async (variables: QueryApiDocumentationVariables) => {
    const response = await client.query<
      QueryApiDocumentation,
      QueryApiDocumentationVariables
    >({
      query: apiDocumentationQuery,
      variables
    });
    return response.data?.solutions?.solution?.useCases?.useCase?.publicApi
      ?.apiDocumentation;
  };
};
