import { gql, useQuery } from "@apollo/client";
import {
  QueryApiDetail,
  QueryApiDetailVariables
} from "./schema/QueryApiDetail";

export const apiDetail = gql`
  query QueryApiDetail(
    $solutionKey: Key!
    $useCaseKey: Key!
    $useCaseVersion: String
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey, version: $useCaseVersion) {
            publicApi {
              sudoApiKey
              url
            }
          }
        }
      }
    }
  }
`;

export const useQueryApiDetail = (variables: QueryApiDetailVariables) =>
  useQuery<QueryApiDetail, QueryApiDetailVariables>(apiDetail, { variables });
