import { gql, useQuery } from "@apollo/client";
import { ProjectName, ProjectNameVariables } from "./schema/ProjectName";
import { Projects, ProjectsVariables } from "./schema/Projects";

const projectsList = gql`
  query Projects($solutionKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          projects {
            databaseName
            description
            id
            image
            name
            tags
            state
            error
            workspaceId
            workspaceName
          }
        }
      }
    }
  }
`;

const queryProjectName = gql`
  query ProjectName($solutionKey: Key!, $projectId: ID!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          project(projectId: $projectId) {
            id
            name
          }
        }
      }
    }
  }
`;

export const useProjectsDataWithPolling = (solutionKey: string) => {
  const { data, loading, error } = useQuery<Projects, ProjectsVariables>(
    projectsList,
    { variables: { solutionKey }, pollInterval: 30000 }
  );

  return { data, loading, error };
};

export const useProjectName = (variables: ProjectNameVariables) =>
  useQuery<ProjectName>(queryProjectName, {
    variables,
    fetchPolicy: "cache-first"
  });
