import { Error } from "@mui/icons-material";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography
} from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import copy from "copy-to-clipboard";
import { FC } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#F5F7F7",
      width: "100%"
    },
    content: {
      display: "flex",
      flexDirection: "column",
      border: "solid 1px #ccc"
    },
    iconWrapper: { textAlign: "center" },
    icon: {
      color: theme.palette.error.main,
      margin: theme.spacing(2),
      height: 30,
      width: 30
    },
    title: {
      color: "#68777B",
      fontWeight: 400,
      fontSize: 20,
      textAlign: "center"
    },
    actions: {
      backgroundColor: theme.palette.error.main,
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start"
    },
    button: {
      color: "#FFFFFF",
      border: "none",
      margin: theme.spacing(1)
    },
    itemText: {
      overflowX: "hidden",
      overflowY: "auto",
      wordBreak: "break-word"
    },
    list: {
      overflow: "auto",
      maxHeight: 390,
      padding: theme.spacing(2)
    },
    item: {
      paddingLeft: 0
    }
  })
);

interface ProjectErrorProps {
  errors: string[];
  database: string;
}

const ProjectError: FC<ProjectErrorProps> = ({ errors, database }) => {
  const classes = useStyles({});
  return (
    <Grid item container>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.iconWrapper}>
            <Error className={classes.icon} fontSize="large" />
          </div>

          <Typography className={classes.title}>
            {`An error occurred while creating ${database} database`}
          </Typography>
          <List className={classes.list}>
            {errors.map((e, index) => {
              return (
                <ListItem key={index} className={classes.item}>
                  <ListItemText primary={e} className={classes.itemText} />
                </ListItem>
              );
            })}
          </List>
        </div>
        <div className={classes.actions}>
          <Tooltip title={"Copy error"}>
            <Button
              className={classes.button}
              variant="outlined"
              onClick={event => {
                event.preventDefault();
                const message = errors.join("\n");
                copy(message);
              }}
            >
              COPY
            </Button>
          </Tooltip>
        </div>
      </div>
    </Grid>
  );
};
export default ProjectError;
