import { Amplify, Hub } from "aws-amplify";
import { History } from "history";
import amplifyConfig from "./amplifyConfig";

export default function authenticatorSetup(history: History<unknown>) {
  Amplify.configure(amplifyConfig);
  Hub.listen("auth", ({ payload: { event, data } }) => {
    if (event === "customOAuthState") {
      history.replace(data);
    }
  });
}
