import { gql, useQuery } from "@apollo/client";
import {
  QueryUseCaseVersions,
  QueryUseCaseVersionsVariables
} from "./schema/QueryUseCaseVersions";

export type Versions = QueryUseCaseVersions;

export const queryUseCaseVersions = gql`
  query QueryUseCaseVersions($solutionKey: Key!, $useCaseKey: Key!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCases(useCaseKey: $useCaseKey) {
            detail {
              version
            }
          }
        }
      }
    }
  }
`;

export default function useQueryUseCaseVersions(
  variables: QueryUseCaseVersionsVariables
) {
  return useQuery<QueryUseCaseVersions, QueryUseCaseVersionsVariables>(
    queryUseCaseVersions,
    {
      variables
    }
  );
}
