import { gql, useMutation } from "@apollo/client";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBoxDialog } from "components/error";
import { useSolutionKey } from "features/solution";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import useInviteSolutionMemberReducer from "./hooks/useInviteSolutionMemberReducer";
import InviteMemberForm from "./InviteMemberForm";
import {
  InviteSolutionMember,
  InviteSolutionMemberVariables
} from "./schema/InviteSolutionMember";

export const inviteSolutionMemberMutation = gql`
  mutation InviteSolutionMember(
    $solutionKey: Key!
    $email: String!
    $groups: [String!]
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        access {
          inviteSolutionMember(email: $email, groups: $groups) {
            name
          }
        }
      }
    }
  }
`;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    link: { display: "inline-flex", textDecoration: "none" },
    paper: { padding: theme.spacing(3), width: 600 },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    }
  })
);

export default function InviteMember() {
  const solutionKey = useSolutionKey();
  const classes = useStyles();
  const initialState = {
    email: "",
    groups: []
  };
  const [state, dispatch] = useInviteSolutionMemberReducer(initialState);
  const history = useHistory();
  const [inviteSolutionMember, { error: inviteSolutionMemberError, reset }] =
    useMutation<InviteSolutionMember, InviteSolutionMemberVariables>(
      inviteSolutionMemberMutation,
      {
        onCompleted: _ =>
          history.push(createRoute(`/solutions/${solutionKey}/members`)),
        onError(error) {
          console.log(error);
        }
      }
    );
  return (
    <Grid container justifyContent="center">
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Typography className={classes.title}>Invite member</Typography>
          <InviteMemberForm
            solutionKey={solutionKey}
            state={state}
            dispatch={dispatch}
            onSubmit={e => {
              e.preventDefault();
              inviteSolutionMember({
                variables: {
                  solutionKey,
                  email: state.email,
                  groups: state.groups
                }
              });
            }}
          />
          <Grid item>
            <Link
              className={classes.link}
              to={createRoute(`/solutions/${solutionKey}/members`)}
            >
              <Button
                color="primary"
                variant="contained"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
            </Link>
            <Button
              form="memberForm"
              type="submit"
              id="invite-member"
              variant="contained"
              data-testid="invite-member"
              disabled={_.isEmpty(state.email)}
              classes={{
                root: classes.createButton,
                disabled: classes.disabledButton
              }}
            >
              Invite
            </Button>
          </Grid>
        </Grid>
        <ErrorBoxDialog
          apolloError={inviteSolutionMemberError}
          onClose={reset}
        />
      </Paper>
    </Grid>
  );
}
