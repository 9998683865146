import makeStyles from "@mui/styles/makeStyles";

const CARD_WIDTH = 350;
const CARD_AREA = 210;
const CARD_ACTION = 43;
const CARD_HEIGHT = CARD_AREA;

//@ts-ignore
export const useStyles = makeStyles(theme => ({
  card: {
    position: "relative"
  },
  container: {
    display: "flex",
    width: CARD_WIDTH,
    height: CARD_AREA,
    backgroundColor: "#FFFFFF",
    color: theme.palette.text.primary
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "rgba(0,0,0,0.08)",
    position: "absolute",
    width: CARD_WIDTH,
    height: CARD_HEIGHT
  },
  image: {
    maxWidth: "100%",
    maxHeight: 140,
    zIndex: 1
  },
  link: { textDecoration: "none" },
  actions: {
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: theme.spacing(1, 0, 0, 0),
    justifyContent: "space-between",
    height: CARD_ACTION
  },
  right: {
    width: "30%",
    display: "flex",
    justifyContent: "flex-end",
    position: "relative",
    "&:before": {
      content: `""`,
      pointerEvents: "none",
      position: "absolute",
      left: 0,
      top: 0,
      width: 125,
      height: CARD_HEIGHT,
      transformOrigin: "bottom left",
      transform: "skewX(20deg)",
      backgroundColor: "#FFFFFF"
    }
  },
  left: {
    width: "70%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 0, 0, 1)
  },
  titleContainer: {
    display: "flex",
    padding: theme.spacing(1),
    color: "#FFFFFF"
  },
  title: {
    textTransform: "capitalize",
    width: "75%",
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  leftMiddle: {
    flex: 2,
    height: "100%",
    width: "75%",
    paddingLeft: theme.spacing(1),
    position: "relative",
    overflow: "hidden",
    "&:hover": {
      "&>div": {
        opacity: 1
      }
    }
  },
  descriptionClass: {
    textAlign: "left",
    textTransform: "capitalize",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 5,
    WebkitBoxOrient: "vertical",
    whiteSpace: "pre-wrap",
    color: "#FFFFFF"
  },
  tagsList: {
    listStyle: "none",
    paddingLeft: 0,
    margin: 0,
    overflow: "hidden"
  },
  tagsListItem: {
    textTransform: "capitalize",
    textAlign: "left",
    color: "#FFFFFF",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  iconWrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    padding: theme.spacing(1)
  },
  subtitleText: {
    padding: theme.spacing(0, 0, 1, 1),
    color: theme.palette.common.white
  },
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  tooltip: {
    whiteSpace: "pre-wrap",
    overflow: "auto",
    overflowX: "hidden",
    maxHeight: 500
  }
}));
