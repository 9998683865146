import { CheckCircle, Publish } from "@mui/icons-material";
import { Grid, LinearProgress, TextField } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import clsx from "clsx";
import TagsInput from "components/form/TagsInput";
import { Select } from "components/select";
import { useSolutionKey } from "features/solution";
import { useFormStyles } from "features/use-case";
import {
  FormikErrors,
  FormikHandlers,
  FormikTouched,
  FormikValues
} from "formik";
import React, { Dispatch, FC } from "react";
import { DropzoneArea } from "react-mui-dropzone";
import { DatabaseBackups_solutions_solution_projects_databaseTemplates as DatabaseTemplate } from "./hooks/schema/DatabaseBackups";
import { ScsVersions_workspaces_workspaces as Version } from "./hooks/schema/ScsVersions";
import { useDatabaseBackups } from "./hooks/useDatabaseBackups";
import { Action, State } from "./hooks/usePublishProjectReducer";
import { useScsVersions } from "./hooks/useScsVersions";

function readDataUrl(file?: Blob) {
  return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    if (!file) return reject();
    const reader = new FileReader();
    reader.onabort = reject;
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: { width: "100%" },
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    link: { display: "inline-flex", textDecoration: "none" },
    select: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 1, 0)
    },
    paper: { padding: theme.spacing(3) },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    }
  })
);

interface AddProjectFormProps {
  state: State;
  dispatch: Dispatch<Action>;
  editProject: boolean;
  errors: FormikErrors<FormikValues>;
  touched: FormikTouched<FormikValues>;
  handleChange: FormikHandlers["handleChange"];
  handleBlur: FormikHandlers["handleBlur"];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const AddProjectForm: FC<AddProjectFormProps> = ({
  state,
  dispatch,
  editProject,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue
}) => {
  const solutionKey = useSolutionKey();
  const classes = useFormStyles();
  const jss = useStyles();
  const { data: databaseBackups } = useDatabaseBackups(solutionKey);
  const { data: scsVersions } = useScsVersions(solutionKey);
  const [workspaceId, setWorkspaceId] = React.useState(
    state?.workspaceId
      ? {
          name: state.workspaceId
        }
      : null
  );
  const [databaseValue, setDatabaseValue] = React.useState(
    state?.databaseTemplateId
      ? {
          name: state.databaseTemplateId
        }
      : null
  );
  return (
    <form className={classes.form} noValidate>
      <Grid container direction="column">
        <TextField
          id="project-name"
          label="Name"
          name="name"
          inputProps={{ maxLength: 64 }}
          error={Boolean(touched.name && errors.name)}
          value={state.name}
          required
          onBlur={handleBlur}
          onChange={event => {
            const value = event.target.value;
            dispatch({ type: "SET_NAME", value });
            setFieldValue("name", value);
            handleChange(event);
          }}
          helperText={typeof errors.name === "string" && errors.name}
          margin="normal"
          variant="outlined"
        />
        <TextField
          id="project-description"
          label="Description"
          name="description"
          inputProps={{ maxLength: 1000 }}
          error={Boolean(errors.description)}
          multiline={true}
          minRows={2}
          maxRows={10}
          value={state.description || ""}
          onBlur={handleBlur}
          onChange={e => {
            e.persist();
            const value = e.target.value;
            dispatch({ type: "SET_DESCRIPTION", value });
            setFieldValue("description", value);
            handleChange(e);
          }}
          margin="normal"
          variant="outlined"
        />
        <Grid container>
          <div className={clsx(classes.relative, classes.adjoin)}>
            <div className={classes.fileUploadProgress}>
              {state.loadImage && <LinearProgress />}
            </div>
            <DropzoneArea
              useChipsForPreview
              //When we replace the upload icon with a custom icon, Typescript reports an error which is described in this issue (https://github.com/Yuvaleros/material-ui-dropzone/issues/246)
              //@ts-ignore
              Icon={state.image ? CheckCircle : Publish}
              onDrop={async file => {
                dispatch({ type: "LOAD_ICON", value: true });
                const value = await readDataUrl(file[0]);
                dispatch({ type: "DROP_ICON", value });
                setFieldValue("icon", value);
              }}
              onDelete={() => {
                dispatch({ type: "DROP_ICON", value: null });
                setFieldValue("icon", null);
              }}
              filesLimit={1}
              acceptedFiles={["image/*"]}
              dropzoneText={"Drag or drop here or browse project icon"}
              initialFiles={state.image ? [state.image] : []}
              classes={{
                root: clsx(
                  classes.dropzoneClass,
                  classes.dropzoneBorderColor,
                  !state.image
                    ? classes.uploadUncompleted
                    : classes.uploadCompleted
                ),
                icon: state.image
                  ? classes.uploadCompletedIcon
                  : classes.uploadUncompletedIcon,
                text: classes.text,
                textContainer: classes.textContainer
              }}
              previewGridClasses={{
                container: classes.gridContainer
              }}
              previewChipProps={{
                classes: {
                  root: classes.maxWidth
                }
              }}
            />
          </div>
        </Grid>
        {!editProject && (
          <Grid container spacing={2}>
            <Grid item sm={!editProject ? 6 : 12}>
              <div className={jss.select}>
                <Select
                  options={scsVersions?.workspaces.workspaces || []}
                  getOptionLabel={(option?: Version) => option?.name || ""}
                  label="Workspace *"
                  name="workspaceId"
                  onBlur={handleBlur}
                  onChange={(event, value: Version | null) => {
                    setWorkspaceId(value);
                    dispatch({
                      type: "SET_WORKSPACE",
                      value: value?.id
                    });
                    setFieldValue("workspaceId", value?.id);
                    handleChange(event);
                  }}
                  error={Boolean(touched.workspaceId && errors.workspaceId)}
                  value={workspaceId}
                  helperText={
                    touched.workspaceId &&
                    typeof errors.workspaceId === "string"
                      ? errors.workspaceId
                      : null
                  }
                  id="workspaceId"
                />
              </div>
            </Grid>

            <Grid item sm={6}>
              <div className={jss.select}>
                <Select
                  options={
                    databaseBackups?.solutions.solution?.projects
                      ?.databaseTemplates || []
                  }
                  getOptionLabel={(option?: DatabaseTemplate) =>
                    option?.name || ""
                  }
                  label="DB Template *"
                  name="databaseTemplate"
                  onBlur={handleBlur}
                  onChange={(event, value: DatabaseTemplate | null) => {
                    setDatabaseValue(value);
                    dispatch({
                      type: "SET_DATABASE_TEMPLATE",
                      value: value?.id || ""
                    });
                    setFieldValue("databaseTemplate", value?.id);
                    handleChange(event);
                  }}
                  error={Boolean(
                    touched.databaseTemplate && errors.databaseTemplate
                  )}
                  value={databaseValue}
                  helperText={
                    touched.databaseTemplate &&
                    typeof errors.databaseTemplate === "string"
                      ? errors.databaseTemplate
                      : null
                  }
                  id="databaseTemplate"
                />
              </div>
            </Grid>
          </Grid>
        )}
        <TagsInput
          tags={state.tags || []}
          dispatch={dispatch}
          setFieldValue={setFieldValue}
        />
      </Grid>
    </form>
  );
};

export default AddProjectForm;
