import { gql, useMutation } from "@apollo/client";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { Formik, FormikErrors, FormikValues } from "formik";
import { Link, useHistory } from "react-router-dom";
import { isAsciiString } from "utils/addForm";
import { createRoute } from "utils/url";
import AddSolutionForm from "./AddSolutionForm";
import useAddSolutionReducer, { State } from "./hooks/useAddSolutionReducer";

export const mutationAddSolution = gql`
  mutation addSolution($solutionDefinition: SolutionDefinitionInput!) {
    solutions {
      addSolution(solutionDefinition: $solutionDefinition) {
        archived
        creationTime
        creator {
          firstName
          lastName
          name
        }
        description
        key
        name
        features {
          projects
          useCases
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    },
    flex: {
      display: "flex",
      flexDirection: "column"
    },
    paper: { padding: theme.spacing(3) },
    link: { display: "inline-flex", textDecoration: "none" },
    cancelButton: {
      color: "#FFFFFF !important",
      marginRight: `${theme.spacing(1)} !important`,
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF !important",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    disabledButton: {
      backgroundColor: "#C7CFD4 !important",
      color: "#68777B !important"
    },
    buttons: { marginTop: theme.spacing(5) },
    margin: {
      marginTop: theme.spacing(1)
    }
  });
});

interface AddSolutionFormWrapperProps {
  initialState?: State;
}

export default function AddSolution({
  initialState = {
    name: "",
    key: "",
    autoGeneratedKey: true,
    description: "",
    projectsEnabled: true,
    useCasesEnabled: true
  }
}: AddSolutionFormWrapperProps) {
  const classNames = useStyles({});

  const onCompleted = (data: any) => {
    const mutatedId = data?.solutions?.addSolution?.key;
    if (mutatedId) {
      history.push(createRoute(`/solutions/${mutatedId}`));
    }
  };

  const [addSolution, { error: createSolutionError, reset: resetAddError }] =
    useMutation<any, any>(mutationAddSolution, { onCompleted });
  const [state, dispatch] = useAddSolutionReducer(initialState);

  const history = useHistory();

  const { name, description, key, projectsEnabled, useCasesEnabled } = state;
  const initialValues = {
    name,
    key,
    description,
    projectsEnabled: true,
    useCasesEnabled: true
  };

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};
    if (values.key === "") {
      errors.key = "Solution Key is required";
    } else if (/[^a-z0-9-]/.test(values.key)) {
      errors.key = "Key must only contain lowercase letters, digits and dashes";
    }
    if (values.name === "") {
      errors.name = "Solution Name is required";
    } else if (!isAsciiString(values.name)) {
      errors.name = "Name must contain Ascii characters only";
    }
    if (values.description === "") {
      errors.description = "Solution description is required";
    }
    return errors;
  };
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item sm={6}>
        <Paper className={classNames.paper}>
          <Typography className={classNames.title}>NEW SOLUTION</Typography>
          <div>
            <Formik
              initialValues={initialValues}
              validate={validate}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setSubmitting(false);
                }, 500);
              }}
            >
              {({
                submitForm,
                isSubmitting,
                dirty,
                isValid,
                handleReset,
                ...restOfProps
              }) => {
                return (
                  <div className={classNames.flex}>
                    <AddSolutionForm
                      {...restOfProps}
                      state={state}
                      dispatch={dispatch}
                    />
                    {createSolutionError ? (
                      <div className={classNames.margin}>
                        <ErrorBox
                          apolloError={createSolutionError}
                          onClose={() => {
                            resetAddError();
                          }}
                          closable
                          maxHeight={130}
                        />
                      </div>
                    ) : (
                      <div className={classNames.buttons}>
                        <Link className={classNames.link} to={createRoute(`/`)}>
                          <Button
                            color="primary"
                            variant="contained"
                            className={classNames.cancelButton}
                          >
                            Cancel
                          </Button>
                        </Link>
                        <Button
                          disabled={!dirty || !isValid}
                          id="add-solution"
                          variant="contained"
                          classes={{
                            root: classNames.createButton,
                            disabled: classNames.disabledButton
                          }}
                          className={classNames.createButton}
                          data-testid="create-solution"
                          onClick={() => {
                            submitForm();
                            addSolution({
                              variables: {
                                solutionDefinition: {
                                  key,
                                  name,
                                  description,
                                  projectsEnabled,
                                  useCasesEnabled
                                }
                              }
                            });
                          }}
                        >
                          Create solution
                        </Button>
                      </div>
                    )}
                  </div>
                );
              }}
            </Formik>
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}
