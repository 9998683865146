import { gql, useMutation } from "@apollo/client";
import { Grid, Paper, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { ErrorBox } from "components/error";
import { useSolutionKey } from "features/solution";
import { Formik, FormikErrors, FormikValues } from "formik";
import { isEmpty } from "lodash";
import { FC, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { createRoute } from "utils/url";
import AddProjectForm from "./AddProjectForm";
import usePublishProjectReducer, {
  State
} from "./hooks/usePublishProjectReducer";
import { Project } from "./hooks/useQueryProjectById";
import { addProject, addProjectVariables } from "./schema/addProject";
import { updateProject, updateProjectVariables } from "./schema/updateProject";

const mutationPublishProject = gql`
  mutation addProject(
    $solutionKey: Key!
    $projectDefinition: ProjectDefinitionInput!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          addProject(projectDefinition: $projectDefinition) {
            databaseName
            description
            id
            image
            name
            tags
            databaseTemplateId
            state
          }
        }
      }
    }
  }
`;
const mutationUpdateProject = gql`
  mutation updateProject(
    $solutionKey: Key!
    $projectId: ID!
    $description: String
    $image: String
    $name: String!
    $tags: [String!]
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          updateProject(
            projectId: $projectId
            description: $description
            image: $image
            name: $name
            tags: $tags
          ) {
            databaseName
            description
            id
            image
            name
            tags
            databaseTemplateId
            state
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cancelButton: {
      color: "#FFFFFF",
      marginRight: theme.spacing(1),
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: 0.8
      }
    },
    createButton: {
      color: "#FFFFFF",
      backgroundColor: theme.palette.primary.dark,
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        opacity: 0.8
      }
    },
    link: { display: "inline-flex", textDecoration: "none" },
    select: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      margin: theme.spacing(2, 0, 1, 0)
    },
    paper: { padding: theme.spacing(3) },
    title: {
      color: theme.palette.primary.main,
      marginBottom: theme.spacing(1)
    }
  })
);

interface AddProjectProps {
  project?: Project;
  initialState?: State;
  projectId?: string;
  editProject?: boolean;
}

type ProjectFormValues = Omit<
  Project,
  | "databaseName"
  | "databaseProgress"
  | "__typename"
  | "error"
  | "id"
  | "state"
  | "isAvailable"
>;

const AddProject: FC<AddProjectProps> = ({ project, editProject = false }) => {
  const solutionKey = useSolutionKey();
  const classNames = useStyles();

  const newProject: ProjectFormValues = {
    name: "",
    description: "",
    tags: [],
    databaseTemplateId: "",
    image: null,
    workspaceId: "",
    workspaceName: ""
  };

  const [state, dispatch] = usePublishProjectReducer(project || newProject);
  const history = useHistory();

  const onCompleted = (data: updateProject | addProject) => {
    const projects = data?.solutions.solution?.projects;
    if (!projects) return null;
    const mutatedId =
      "updateProject" in projects
        ? projects.updateProject?.id
        : projects.addProject?.id;
    if (mutatedId) {
      history.push(
        createRoute(`/solutions/${solutionKey}/projects/project/${mutatedId}`)
      );
    }
  };

  const [addProject, { error: addError, reset: resetAddError }] = useMutation<
    addProject,
    addProjectVariables
  >(mutationPublishProject, { onCompleted });

  const [updateProject, { error: updateError, reset: resetUpdateError }] =
    useMutation<updateProject, updateProjectVariables>(mutationUpdateProject, {
      onCompleted
    });

  const mutationError = addError || updateError;

  const validate = (values: FormikValues) => {
    const errors: FormikErrors<FormikValues> = {};
    if (isEmpty(values.name)) errors.name = "Name is required";
    if (project === undefined) {
      if (isEmpty(values.workspaceId))
        errors.workspaceId = "Workspace is required";
      if (isEmpty(values.databaseTemplate))
        errors.databaseTemplate = "Database Template is required";
    }
    return errors;
  };

  const cancelEditRoute = project
    ? `/solutions/${solutionKey}/projects/project/${project.id}`
    : `/solutions/${solutionKey}/projects`;

  return (
    <Fragment>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item sm={6}>
          <Paper className={classNames.paper}>
            <Typography className={classNames.title}>
              {!editProject ? "NEW PROJECT" : "EDIT PROJECT"}
            </Typography>
            <Formik
              initialValues={project || newProject}
              onSubmit={() => {}}
              validate={validate}
            >
              {({ dirty, isValid, ...restOfProps }) => {
                return (
                  <>
                    <AddProjectForm
                      state={state}
                      dispatch={dispatch}
                      editProject={editProject}
                      {...restOfProps}
                    />
                    <div>
                      {mutationError ? (
                        <ErrorBox
                          apolloError={mutationError}
                          onClose={() => {
                            resetAddError();
                            resetUpdateError();
                          }}
                          closable
                        />
                      ) : (
                        <>
                          <Link
                            className={classNames.link}
                            to={createRoute(cancelEditRoute)}
                          >
                            <Button
                              color="primary"
                              variant="contained"
                              className={classNames.cancelButton}
                            >
                              Cancel
                            </Button>
                          </Link>
                          <Button
                            id="publish-project"
                            variant="contained"
                            className={classNames.createButton}
                            disabled={!dirty || !isValid}
                            onClick={() => {
                              if (!state.name) return;
                              if (editProject) {
                                if (!project?.id) return;
                                updateProject({
                                  variables: {
                                    solutionKey,
                                    projectId: project.id,
                                    description: state.description,
                                    name: state.name,
                                    image: state.image,
                                    tags: state.tags
                                  }
                                });
                              } else {
                                if (!state.workspaceId) return;
                                if (!state.databaseTemplateId) return;
                                addProject({
                                  variables: {
                                    solutionKey,
                                    projectDefinition: {
                                      workspaceId: state.workspaceId,
                                      databaseTemplateId:
                                        state.databaseTemplateId,
                                      name: state.name,
                                      description: state.description,
                                      image: state.image,
                                      tags: state.tags
                                    }
                                  }
                                });
                              }
                            }}
                          >
                            {!editProject ? "Create Project" : "Edit Project"}
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                );
              }}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AddProject;
