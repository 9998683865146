import { gql, useQuery } from "@apollo/client";
import {
  NextVersionInfo,
  NextVersionInfoVariables,
  NextVersionInfo_solutions_solution_useCases_useCase_detail_nextVersion_schema,
  NextVersionInfo_solutions_solution_useCases_useCase_detail_nextVersion_schemaDiff
} from "./schema/NextVersionInfo";
import { UseCaseSchema, UseCaseSchemaVariables } from "./schema/UseCaseSchema";

export type NextVersionInfoProps = NextVersionInfoVariables;
export type SchemaDiff =
  NextVersionInfo_solutions_solution_useCases_useCase_detail_nextVersion_schemaDiff;
export type Schema =
  NextVersionInfo_solutions_solution_useCases_useCase_detail_nextVersion_schema;

const queryNextVersionInfo = gql`
  query NextVersionInfo(
    $solutionKey: Key!
    $calculationRuleAddress: String!
    $useCaseKey: Key!
  ) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCase(useCaseKey: $useCaseKey) {
            detail {
              nextVersion(calculationRuleAddress: $calculationRuleAddress) {
                releaseType
                version
                schema {
                  input {
                    columns {
                      nullable
                      name
                      typeName
                    }
                    name
                  }
                  output {
                    columns {
                      name
                      nullable
                      typeName
                    }
                    name
                  }
                }
                schemaDiff {
                  input {
                    changedTables {
                      name
                      changedColumns {
                        from {
                          name
                          nullable
                          typeName
                        }
                        to {
                          name
                          nullable
                          typeName
                        }
                      }
                      missingColumns {
                        name
                        nullable
                        typeName
                      }
                      newColumns {
                        name
                        nullable
                        typeName
                      }
                    }
                    missingTables {
                      name
                      columns {
                        name
                        nullable
                        typeName
                      }
                    }
                    newTables {
                      name
                      columns {
                        name
                        nullable
                        typeName
                      }
                    }
                  }
                  output {
                    changedTables {
                      name
                      changedColumns {
                        from {
                          name
                          nullable
                          typeName
                        }
                        to {
                          name
                          nullable
                          typeName
                        }
                      }
                      missingColumns {
                        name
                        typeName
                        nullable
                      }
                      newColumns {
                        name
                        nullable
                        typeName
                      }
                    }
                    missingTables {
                      name
                      columns {
                        name
                        nullable
                        typeName
                      }
                    }
                    newTables {
                      name
                      columns {
                        name
                        nullable
                        typeName
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const queryUseCaseSchema = gql`
  query UseCaseSchema($solutionKey: Key!, $calculationRuleAddress: String!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        useCases {
          useCaseSchema(calculationRuleAddress: $calculationRuleAddress) {
            input {
              columns {
                nullable
                name
                typeName
              }
              name
            }
            output {
              columns {
                name
                nullable
                typeName
              }
              name
            }
          }
        }
      }
    }
  }
`;

export function useQueryChangePreview(variables: NextVersionInfoVariables) {
  return useQuery<NextVersionInfo, NextVersionInfoVariables>(
    queryNextVersionInfo,
    {
      variables
    }
  );
}

export function useQueryUseCaseSchema(variables: UseCaseSchemaVariables) {
  return useQuery<UseCaseSchema, UseCaseSchemaVariables>(queryUseCaseSchema, {
    variables
  });
}
