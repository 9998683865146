import { useSolutionKey } from "features/solution";
import { SolutionPermission } from ".";
import { AtLeastOnePermission } from "./RestrictedRealm";
import { missingPermissions } from "./missingPermissions";
import usePermissionsData from "./usePermissionsData";

export default function useRequestUserSolutionPermissions(
  requestedPermissions: AtLeastOnePermission<SolutionPermission>
) {
  const solutionKey = useSolutionKey();
  const { data: permissionsData, ...queryResult } =
    usePermissionsData(solutionKey);
  const solutionMember = permissionsData?.solutions.solution?.access?.me;
  const solutionMemberPermissions = solutionMember?.permissions || [];
  const missingMemberPermissions = missingPermissions(
    requestedPermissions,
    solutionMemberPermissions
  );

  return {
    ...queryResult,
    ...missingMemberPermissions
  };
}
