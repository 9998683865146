import { gql, useMutation, useQuery } from "@apollo/client";
import {
  UpdateCapacity,
  UpdateCapacityVariables
} from "./schema/UpdateCapacity";
import {
  UpdateScalingPolicy,
  UpdateScalingPolicyVariables
} from "./schema/UpdateScalingPolicy";
import { Workspace, WorkspaceVariables } from "./schema/Workspace";

import {
  WorkspaceCapacity,
  WorkspaceCapacityVariables
} from "./schema/WorkspaceCapacity";
import { WorkspaceName, WorkspaceNameVariables } from "./schema/WorkspaceName";
import {
  WorkspaceNameById,
  WorkspaceNameByIdVariables
} from "./schema/WorkspaceNameById";

export const queryWorkspaceName = gql`
  query WorkspaceName($solutionKey: Key!, $projectId: ID!) {
    solutions {
      solution(solutionKey: $solutionKey) {
        projects {
          project(projectId: $projectId) {
            workspaceName
          }
        }
      }
    }
  }
`;

const queryWorkspace = gql`
  query Workspace($workspaceId: ID!) {
    workspaces {
      workspace(workspaceId: $workspaceId) {
        capacity {
          computeCapacityStatus {
            available
            desired
            inUse
            running
          }
          maxCapacity
          minCapacity
        }
        scaleInPolicy {
          scalingAdjustment
          scalingType
          threshold
        }
        scaleOutPolicy {
          scalingAdjustment
          scalingType
          threshold
        }
        id
        name
        isAvailable
      }
    }
  }
`;

const queryWorkspaceNameById = gql`
  query WorkspaceNameById($workspaceId: ID!) {
    workspaces {
      workspace(workspaceId: $workspaceId) {
        id
        name
      }
    }
  }
`;

export const workspaceCapacity = gql`
  query WorkspaceCapacity($workspaceId: ID!) {
    workspaces {
      workspace(workspaceId: $workspaceId) {
        capacity {
          computeCapacityStatus {
            desired
            running
          }
        }
      }
    }
  }
`;

const mutationUpdateCapacity = gql`
  mutation UpdateCapacity(
    $workspaceId: ID!
    $minCapacity: Int!
    $maxCapacity: Int!
  ) {
    workspaces {
      workspace(workspaceId: $workspaceId) {
        updateCapacity(maxCapacity: $maxCapacity, minCapacity: $minCapacity) {
          maxCapacity
          minCapacity
          computeCapacityStatus {
            available
            desired
            inUse
            running
          }
        }
      }
    }
  }
`;

const mutationUpdateScalingPolicy = gql`
  mutation UpdateScalingPolicy(
    $workspaceId: ID!
    $scalingAdjustment: Int!
    $scalingType: ScalingType!
    $threshold: Int!
  ) {
    workspaces {
      workspace(workspaceId: $workspaceId) {
        updateScalingPolicy(
          scalingAdjustment: $scalingAdjustment
          scalingType: $scalingType
          threshold: $threshold
        ) {
          scalingAdjustment
          scalingType
          threshold
        }
      }
    }
  }
`;

//capacity hooks

export const useWorkspaceName = (variables: WorkspaceNameVariables) =>
  useQuery<WorkspaceName>(queryWorkspaceName, {
    variables,
    fetchPolicy: "cache-first"
  });

export const useWorkspaceNameById = (variables: WorkspaceNameByIdVariables) =>
  useQuery<WorkspaceNameById, WorkspaceNameByIdVariables>(
    queryWorkspaceNameById,
    {
      variables,
      fetchPolicy: "cache-first"
    }
  );

export const useQueryWorkspace = (variables: WorkspaceVariables) =>
  useQuery<Workspace>(queryWorkspace, {
    variables,
    pollInterval: 15000
  });

export const useWorkspaceCapacity = (variables: WorkspaceCapacityVariables) =>
  useQuery<WorkspaceCapacity>(workspaceCapacity, {
    variables,
    fetchPolicy: "cache-first"
  });
export const useMutationUpdateCapacity = () => {
  const [updateWorkspaceCapacity, { error, data }] =
    useMutation<UpdateCapacity>(mutationUpdateCapacity);
  const updateCapacity = async ({
    workspaceId,
    minCapacity,
    maxCapacity
  }: UpdateCapacityVariables) => {
    await updateWorkspaceCapacity({
      variables: { workspaceId, minCapacity, maxCapacity }
    });
  };

  return {
    updateCapacity,
    data,
    error
  };
};

export const useMutationUpdateScalingPolicy = (
  variables: WorkspaceVariables
) => {
  const [updatePolicy, { error, data }] = useMutation<UpdateScalingPolicy>(
    mutationUpdateScalingPolicy
  );
  const updateScalingPolicy = async ({
    workspaceId,
    scalingAdjustment,
    scalingType,
    threshold
  }: UpdateScalingPolicyVariables) => {
    await updatePolicy({
      variables: { workspaceId, scalingAdjustment, scalingType, threshold },
      refetchQueries: [{ query: queryWorkspace, variables }]
    });
  };

  return {
    updateScalingPolicy,
    data,
    error
  };
};
