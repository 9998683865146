import { gql, useQuery } from "@apollo/client";
import { PublicApiPermissions } from "./schema/PublicApiPermissions";

export const publicApiPermissionsQuery = gql`
  query PublicApiPermissions {
    publicApi {
      access {
        myPermissions
      }
    }
  }
`;
export default function usePublicApiPermissions() {
  return useQuery<PublicApiPermissions>(publicApiPermissionsQuery, {
    fetchPolicy: "cache-first"
  });
}
