import { gql, useMutation, useQuery } from "@apollo/client";
import { Button, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import DataGrid, {
  FilterRow,
  Paging,
  Scrolling,
  Selection
} from "devextreme-react/data-grid";
import DropDownBox from "devextreme-react/drop-down-box";
import {
  PublicApiPermission,
  RestrictedPublicApiRealm
} from "features/access-management";
import { isEqual, map, property } from "lodash";
import { useRef, useState } from "react";
import "../../utils/custom-dx-theme/dx.material.custom-scheme.css";
import {
  GrantApiKeyAccess,
  GrantApiKeyAccessVariables
} from "./schema/GrantApiKeyAccess";
import { QueryAvailableSolutions } from "./schema/QueryAvailableSolutions";
import { QueryResolveAccess } from "./schema/QueryResolveAccess";
import {
  RevokeApiKeyAccess,
  RevokeApiKeyAccessVariables
} from "./schema/RevokeApiKeyAccess";

export const resolveAccessQuery = gql`
  query QueryResolveAccess($apiKey: String!) {
    publicApi {
      access {
        resolveAccess(apiKey: $apiKey) {
          key
        }
      }
    }
  }
`;

export const availableSolutionsQuery = gql`
  query QueryAvailableSolutions {
    solutions {
      allSolutions {
        name
        key
      }
    }
  }
`;

const grantApiKeyAccessMutation = gql`
  mutation GrantApiKeyAccess(
    $apiKey: String!
    $solutionPermissions: [SolutionPermissionInput]!
  ) {
    publicApi {
      access {
        grantApiKeyAccess(
          apiKey: $apiKey
          solutionPermissions: $solutionPermissions
        )
      }
    }
  }
`;

const revokeApiKeyAccessMutation = gql`
  mutation RevokeApiKeyAccess($apiKey: String!) {
    publicApi {
      access {
        revokeApiKeyAccess(apiKey: $apiKey)
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  flex: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "#fff"
    }
  },
  disabledButton: {
    backgroundColor: "#C7CFD4",
    color: "#68777B"
  }
}));
const ownerLabel = { "aria-label": "Owner" };

export default function ApiKeyDetail({
  data: {
    data: { value: apiKeyValue }
  }
}: any) {
  const classes = useStyles();
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const dropDownBoxRef = useRef<DropDownBox>(null);
  const [isSaveSolutionsDisabled, setIsSaveSolutionsDisabled] = useState(true);
  const { data: apiKeySolutionsKeys, loading } = useQuery<QueryResolveAccess>(
    resolveAccessQuery,
    {
      variables: { apiKey: apiKeyValue }
    }
  );

  const { data: allAvailableSolutions } = useQuery<QueryAvailableSolutions>(
    availableSolutionsQuery
  );

  const resolveAccessKeys: string[] = map(
    apiKeySolutionsKeys?.publicApi.access.resolveAccess,
    property("key")
  );

  const [solutions, setSolutions] = useState<string[]>([]);
  const [grantApiKeyAccess] = useMutation<
    GrantApiKeyAccess,
    GrantApiKeyAccessVariables
  >(grantApiKeyAccessMutation);

  const [revokeApiKeyAccess] = useMutation<
    RevokeApiKeyAccess,
    RevokeApiKeyAccessVariables
  >(revokeApiKeyAccessMutation);

  const handleAssignSolutions = () => {
    const solutionsKeys = map(solutions, keyValue => ({
      key: keyValue
    }));
    if (solutionsKeys.length > 0) {
      grantApiKeyAccess({
        variables: {
          apiKey: apiKeyValue,
          solutionPermissions: solutionsKeys
        },
        refetchQueries: [{ query: resolveAccessQuery }]
      });
    } else {
      revokeApiKeyAccess({
        variables: { apiKey: apiKeyValue },
        refetchQueries: [{ query: resolveAccessQuery }]
      });
    }
    dropDownBoxRef.current?.instance?.close();
  };

  if (loading) return <LinearProgress />;
  function dataGridRender() {
    return (
      <div>
        <div className={classes.flex}>
          <Button
            onMouseDown={handleAssignSolutions}
            ref={saveButtonRef}
            className={classes.button}
            disabled={isSaveSolutionsDisabled}
            classes={{
              root: classes.button,
              disabled: classes.disabledButton
            }}
          >
            Save
          </Button>
        </div>

        <DataGrid
          height={345}
          dataSource={allAvailableSolutions?.solutions.allSolutions}
          columns={["name"]}
          keyExpr="key"
          hoverStateEnabled={true}
          selectedRowKeys={solutions}
          onSelectionChanged={e => {
            const selectedRowKeys = e.selectedRowKeys;
            const noChangeOccurs = isEqual(
              [...resolveAccessKeys].sort(),
              [...selectedRowKeys].sort()
            );
            setSolutions((e.selectedRowKeys.length && e.selectedRowKeys) || []);
            setIsSaveSolutionsDisabled(noChangeOccurs);
          }}
          onKeyDown={e => {
            if (e.event?.key === "Enter") {
              e.event.stopPropagation();
              saveButtonRef.current?.focus();
            }
          }}
        >
          <Selection mode="multiple" />
          <Scrolling mode="virtual" />
          <Paging enabled={true} pageSize={10} />
          <FilterRow visible={true} />
        </DataGrid>
      </div>
    );
  }
  return (
    <RestrictedPublicApiRealm
      requiredPermissions={[PublicApiPermission.ChangeApiKeyAccess]}
    >
      {({ accessGranted }) => {
        return (
          <div className="dx-field">
            <div className="dx-field-label">
              Solutions that can use this API Key
            </div>
            <div className="dx-field-value">
              <DropDownBox
                value={solutions}
                valueExpr="key"
                displayExpr="name"
                deferRendering={false}
                inputAttr={ownerLabel}
                valueChangeEvent="valueChanged"
                placeholder="Select a value..."
                showClearButton={true}
                dataSource={allAvailableSolutions?.solutions.allSolutions}
                onContentReady={() => {
                  setSolutions(resolveAccessKeys);
                }}
                onValueChanged={e => {
                  const keysValue = e.value;
                  setSolutions(keysValue || []);
                }}
                contentRender={dataGridRender}
                disabled={!accessGranted}
                ref={dropDownBoxRef}
              />
            </div>
          </div>
        );
      }}
    </RestrictedPublicApiRealm>
  );
}
