import { Grid } from "@mui/material";
import {
  PublicApiPermission,
  RestrictedPublicApiRealm,
  RestrictedWorkspaceRealm,
  WorkspacePermissions
} from "features/access-management";

import { AccessControlCard } from "features/public-api";
import { SolutionOverview } from "features/solution";
import { WorkspacesCard } from "features/workspace";

export default function LandingPage() {
  return (
    <Grid container spacing={4} height="100%">
      <Grid item xs={12}>
        <SolutionOverview />
      </Grid>
      <Grid container spacing={4} padding={4}>
        <RestrictedPublicApiRealm
          requiredPermissions={[PublicApiPermission.ReadApiKeys]}
          silent={true}
        >
          <Grid item>
            <AccessControlCard />
          </Grid>
        </RestrictedPublicApiRealm>
        <RestrictedWorkspaceRealm
          requiredPermissions={[WorkspacePermissions.ReadWorkspaces]}
          silent={true}
        >
          <Grid item>
            <WorkspacesCard />
          </Grid>
        </RestrictedWorkspaceRealm>
      </Grid>
    </Grid>
  );
}
