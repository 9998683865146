import { LinearProgress } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DataGrid, {
  Column,
  ColumnFixing,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupPanel,
  HeaderFilter,
  Item,
  Paging,
  Scrolling,
  Selection,
  Sorting,
  Toolbar
} from "devextreme-react/data-grid";
import CustomStore from "devextreme/data/custom_store";
import "../../utils/custom-dx-theme/dx.material.custom-scheme.css";
import { ColumnProps, GridProps } from "./gridTypings";

const useStyles = makeStyles(theme => ({
  gridRoot: { overflow: "hidden", height: "100%" },
  progress: { height: "4px" },
  item: {
    minHeight: 50
  }
}));

export const TestIds = {
  loadingIndicator: "loadingIndicator",
  gridContent: "dataGrid"
};

const DataGridView = <TRowData extends {}>({
  rowData,
  columns,
  isLoading,
  withToolbar,
  toolbarItemRender,
  toolbarItemLocation = "after",
  allowGrouping = false,
  showFilterRow = true,
  load,
  allowSelection = true,
  children,
  ...dataGridProps
}: GridProps<TRowData, ColumnProps<TRowData>>) => {
  const classes = useStyles();

  const gridProps = {
    filterSyncEnabled: true,
    cellHintEnabled: true,
    allowColumnReordering: true,
    allowColumnResizing: true,
    showBorders: true,
    height: "100%",
    width: "100%",
    columnAutoWidth: true,
    remoteOperations: false,
    ...dataGridProps
  };

  const { height, remoteOperations } = gridProps;

  return (
    <div className={classes.gridRoot}>
      <div className={classes.progress}>
        {isLoading && <LinearProgress data-testid={TestIds.loadingIndicator} />}
      </div>
      <div
        data-testid={TestIds.gridContent}
        style={{ height: typeof height == "function" ? height() : height }}
      >
        <DataGrid
          dataSource={
            !remoteOperations
              ? rowData
              : new CustomStore({
                  load
                })
          }
          {...gridProps}
        >
          <Scrolling
            mode={remoteOperations ? "virtual" : "standard"}
            columnRenderingMode="standard"
          />
          <Paging enabled={remoteOperations} />
          <GroupPanel visible={allowGrouping} />
          {allowGrouping && <Grouping autoExpandAll={true} />}
          {allowSelection && <Selection mode="single" />}
          <Sorting mode="multiple" />
          <FilterRow visible={showFilterRow} />
          <HeaderFilter visible={true} />
          <FilterPanel visible={false} />
          <ColumnFixing enabled={true} />
          {columns?.map(({ colDef, render, children }, index) => (
            <Column {...colDef} cellRender={render} key={index}>
              {children?.map((props, key) => (
                <Column {...props.colDef} cellRender={props.render} key={key} />
              ))}
            </Column>
          ))}
          {withToolbar && toolbarItemRender ? (
            <Toolbar>
              <Item
                location={toolbarItemLocation}
                render={toolbarItemRender}
                cssClass={classes.item}
              />
            </Toolbar>
          ) : null}
          {children}
        </DataGrid>
      </div>
    </div>
  );
};
export default DataGridView;
