/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum IScopedPermission__PublicApiScope {
  ChangeApiKeyAccess = "ChangeApiKeyAccess",
  ChangeApiKeys = "ChangeApiKeys",
  ReadApiKeyAccess = "ReadApiKeyAccess",
  ReadApiKeys = "ReadApiKeys"
}

export enum IScopedPermission__SolutionScope {
  ArchiveAndRestoreSolution = "ArchiveAndRestoreSolution",
  CalculateUseCases = "CalculateUseCases",
  ChangeProjects = "ChangeProjects",
  ChangeSolutionDetail = "ChangeSolutionDetail",
  ChangeSolutionMemberPermissions = "ChangeSolutionMemberPermissions",
  ChangeUseCaseData = "ChangeUseCaseData",
  ChangeUseCases = "ChangeUseCases",
  DeleteProjects = "DeleteProjects",
  DeletePublicApiRequests = "DeletePublicApiRequests",
  DeleteUseCases = "DeleteUseCases",
  DownloadCalculationRule = "DownloadCalculationRule",
  InviteSolutionMember = "InviteSolutionMember",
  ReadAllCalculations = "ReadAllCalculations",
  ReadAllUseCaseData = "ReadAllUseCaseData",
  ReadConfiguration = "ReadConfiguration",
  ReadProjects = "ReadProjects",
  ReadPublicApi = "ReadPublicApi",
  ReadPublicApiRequests = "ReadPublicApiRequests",
  ReadSolutionDetail = "ReadSolutionDetail",
  ReadSolutionMembers = "ReadSolutionMembers",
  ReadUseCases = "ReadUseCases",
  RemoveSolutionMember = "RemoveSolutionMember"
}

export enum IScopedPermission__WorkspaceManagementScope {
  ChangeWorkspaces = "ChangeWorkspaces",
  ReadWorkspaces = "ReadWorkspaces"
}

export enum JobState {
  Completed = "Completed",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

export enum ProjectState {
  Created = "Created",
  Failed = "Failed",
  InProgress = "InProgress",
  Scheduled = "Scheduled"
}

export enum ReleaseType {
  Initial = "Initial",
  Major = "Major",
  Minor = "Minor",
  Patch = "Patch"
}

export enum ScalingType {
  ScaleIn = "ScaleIn",
  ScaleOut = "ScaleOut"
}

export enum TransactionPackage {
  Large = "Large",
  Medium = "Medium",
  Small = "Small"
}

export interface AddDataCellInput {
  key: string;
  value?: JsonValue | null;
}

export interface AddDataTableInput {
  name: string;
  rows: AddDataCellInput[][];
}

export interface ApiKeyDefinitionInput {
  name: string;
  transactionPackage: TransactionPackage;
  enabled?: boolean | null;
}

export interface ProjectDefinitionInput {
  name: string;
  description?: string | null;
  image?: string | null;
  workspaceId: string;
  databaseTemplateId: string;
  tags?: string[] | null;
}

export interface SolutionDefinitionInput {
  key: Key;
  name: string;
  description: string;
  projectsEnabled: boolean;
  useCasesEnabled: boolean;
}

export interface SolutionPermissionInput {
  key?: Key | null;
}

export interface UseCaseDefinitionInput {
  key: Key;
  name: string;
  tags: string[];
  calculationRuleAddress: string;
  image?: string | null;
  description?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
