import { useReducer } from "react";

export interface State {
  email: string;
  groups: string[];
}

export type Action =
  | { type: "SET_EMAIL"; value: string }
  | { type: "UPDATE_GROUPS"; value: [string] }
  | { type: "RESET" };

export default function useInviteSolutionMemberReducer(initialState: State) {
  function reducer(state: State, action: Action): State {
    switch (action.type) {
      case "SET_EMAIL":
        return { ...state, email: action.value };
      case "UPDATE_GROUPS":
        return { ...state, groups: action.value };

      case "RESET":
        return { ...initialState };
      default:
        throw new Error();
    }
  }

  return useReducer(reducer, initialState);
}
