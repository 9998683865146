import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  cell: {
    display: "flex",
    alignItems: "center",
    height: "100%"
  },
  root: {
    "&.dx-texteditor": {
      borderBottomLeftRadius: `4px !important`,
      borderBottomRightRadius: 4
    },
    "&.dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label":
      {
        top: -5,
        color: " #3AA9AE"
      },
    "&.dx-editor-filled.dx-texteditor-with-floating-label.dx-dropdowneditor-active .dx-texteditor-label,.dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused .dx-texteditor-label,.dx-editor-filled.dx-texteditor-with-floating-label.dx-textarea.dx-state-focused .dx-texteditor-label":
      {
        top: -5,
        color: " #3AA9AE"
      },
    "&.dx-editor-filled.dx-texteditor-with-floating-label .dx-texteditor-label":
      {
        top: -5,
        color: "rgba(0, 0, 0, 0.6)"
      },
    "&.dx-editor-filled.dx-texteditor-with-floating-label.dx-texteditor-empty .dx-texteditor-label":
      {
        top: "50%",
        fontSize: "16px"
      },
    "&.dx-editor-filled.dx-texteditor-with-floating-label.dx-state-focused.dx-texteditor-empty .dx-texteditor-label":
      {
        top: -5,
        fontSize: "16px"
      },
    "&.dx-texteditor.dx-editor-filled": {
      backgroundColor: "#fff"
    },
    "&.dx-texteditor.dx-editor-filled::after": {
      borderBottom: `none !important`
    },

    "&.dx-state-focused::before": {
      borderBottom: `none !important`
    },
    "& .dx-texteditor-label .dx-label": {
      backgroundColor: `#fff !important`
    },
    "&.dx-editor-filled.dx-texteditor-with-floating-label .dx-tag": {
      marginTop: `0px !important`
    },
    "&.dx-editor-filled.dx-texteditor-with-floating-label .dx-tag-content": {
      borderRadius: `4px !important`
    },
    "&.dx-texteditor.dx-editor-filled .dx-tag-content": {
      borderRadius: `4px !important`
    }
  }
}));
