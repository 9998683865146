import { Avatar, Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles<Theme, MemberAvatarProps>(theme => ({
  avatar: ({ large = false }) => ({
    color: "#3AA9AE",
    borderRadius: "0.75rem",
    backgroundColor: "#C9F7F5",
    fontFamily: "monospace",
    fontSize: large ? "24px" : undefined,
    width: large ? 50 : undefined,
    height: large ? 50 : undefined
  })
}));

export interface INameProvider {
  firstName: string | null;
  lastName: string | null;
}

export function createUserAvatarCaption(
  firstName: string,
  lastName: string
): string {
  const shorthand =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  return shorthand || "?";
}
type MemberAvatarProps = {
  member: INameProvider;
  large?: boolean;
  dataTest?: string;
};

export default function MemberAvatar(props: MemberAvatarProps) {
  const { avatar } = useStyles(props);
  const { member, dataTest = "member" } = props;
  return member ? (
    <Avatar data-test={dataTest} className={avatar}>
      {member.firstName !== null && member.lastName !== null
        ? createUserAvatarCaption(member.firstName, member.lastName)
        : "API"}
    </Avatar>
  ) : null;
}
