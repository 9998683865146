import { Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  useMutationUpdateCapacity,
  useQueryWorkspace
} from "../workspaceHooks";
import EditCapacityForm from "./EditCapacityForm";

export const useStyles = makeStyles(() => ({
  root: {
    capacity: {
      gridArea: "capacity"
    }
  },
  warning: { color: "rgba(0, 0, 0, 0.54)", fontSize: "0.875rem" }
}));
export default function Capacity({
  workspaceId,

  editable
}: {
  workspaceId: string;

  editable: boolean;
}) {
  const classNames = useStyles();
  const { data: workspaceCapacity } = useQueryWorkspace({
    workspaceId
  });

  const { updateCapacity, data: mutatedCapacity } = useMutationUpdateCapacity();
  const capacity =
    mutatedCapacity?.workspaces.workspace?.updateCapacity ||
    workspaceCapacity?.workspaces.workspace?.capacity;

  if (!capacity) return <div />;

  return (
    <div className={classNames.root}>
      <Typography variant="h5" gutterBottom>
        Seat capacity
      </Typography>
      <Typography className={classNames.warning}>
        The capacity adjustment might affect all solutions
      </Typography>
      <EditCapacityForm
        max={capacity.maxCapacity}
        min={capacity.minCapacity}
        workspaceId={workspaceId}
        allowToEdit={editable}
        updateCapacity={updateCapacity}
      />
    </div>
  );
}
