import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import UseCaseCalculationsResults from "./UseCaseCalculationsResults";

const AllCalculationsResultsWrapper = ({
  useCaseKey,
  useCaseVersion,
  navigateToCalculate,
  navigateToCalculation
}: {
  useCaseKey: string;
  useCaseVersion: string;
  navigateToCalculate: () => void;
  navigateToCalculation: (calculationId: string) => void;
}) => {
  const [userName, setUserName] = useState("");
  useEffect(() => {
    async function setCurrentUser() {
      const user = await Auth.currentAuthenticatedUser();
      setUserName(user.username);
    }
    setCurrentUser();
  }, []);
  return (
    <UseCaseCalculationsResults
      signedInUser={userName}
      useCaseKey={useCaseKey}
      useCaseVersion={useCaseVersion}
      navigateToCalculate={navigateToCalculate}
      navigateToCalculation={navigateToCalculation}
    />
  );
};

export default AllCalculationsResultsWrapper;
