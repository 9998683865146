import { GetApp } from "@mui/icons-material";
import { Grid, Paper, Skeleton, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import InfoTooltip from "components/base/InfoTooltip";
import { useSolutionKey } from "features/solution";
import FileSaver from "file-saver";
import React, { lazy } from "react";
import { createBlobFromBytesArray } from "utils/blob";
import ApiDataView from "./ApiDataView";
import { useQueryApiDetail } from "./hooks/apiDetailHooks";
import { useQueryApiDocumentation } from "./hooks/apiDocumentationHook";

const ApiTester = lazy(() => import("./ApiTesterContainer"));

export const useStyles = makeStyles(theme => ({
  panel: {
    backgroundColor: theme.palette.background.paper
  },
  panelProjects: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: 0
    }
  },
  panelDetails: {
    padding: theme.spacing(4, 4, 0, 4),
    alignItems: "flex-start"
  },
  panelDefault: {
    padding: theme.spacing(3, 4, 0, 4),
    flexWrap: "nowrap"
  },
  panelProject: {
    padding: theme.spacing(4, 4, 3, 4)
  },
  projectInformation: { padding: theme.spacing(0, 4, 3, 4) },
  heading: {
    flex: "0 1 auto",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    fontWeight: "bold"
  },
  fullSize: {
    display: "flex"
  },
  info: {
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1)
  },
  downloadButton: { cursor: "pointer" },
  dataView: {
    flexWrap: "nowrap"
  },
  button: {
    color: "#ffffff",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.8
    }
  },
  padding: {
    padding: theme.spacing(4, 4, 0, 4)
  },
  divider: { width: "100%", backgroundColor: "rgba(0, 0, 0, 0.08)" },
  flex: { display: "flex", alignItems: "center" },
  date: {
    color: "rgba(0,0,0,.2)",
    fontSize: 12
  },
  margin: {
    marginTop: theme.spacing(1)
  },
  skeletons: { display: "flex" }
}));

type PublicApiProps = {
  useCaseKey: string;
  useCaseVersion: string;
};

const PublicApi: React.FC<PublicApiProps> = ({
  useCaseKey,
  useCaseVersion
}) => {
  const solutionKey = useSolutionKey();
  const css = useStyles({});
  const lazyQueryApiDocumentation = useQueryApiDocumentation();

  const { data: apiDetail, loading: isLoadingApiDataDetail } =
    useQueryApiDetail({
      solutionKey,
      useCaseKey,
      useCaseVersion
    });

  const DownloadButton = ({
    apiKeyId,
    prefix = "default"
  }: {
    apiKeyId?: string | null;
    prefix?: string;
  }) => {
    return (
      <Tooltip
        title={
          <Typography>
            {`Download the ${prefix} specification zip file which describe the public api interface`}
          </Typography>
        }
      >
        <GetApp
          color="secondary"
          className={css.downloadButton}
          data-testid={`${prefix}-download-public-api`}
          onClick={async e => {
            e.stopPropagation();
            const documentation = await lazyQueryApiDocumentation({
              solutionKey,
              useCaseKey,
              useCaseVersion,
              apiKeyId
            });
            if (documentation) {
              FileSaver.saveAs(
                createBlobFromBytesArray(documentation, "application/zip"),
                `API_${prefix}_${useCaseKey}@${useCaseVersion}.zip`
              );
            }
          }}
        />
      </Tooltip>
    );
  };

  return (
    <Paper elevation={0} square>
      <Typography color="primary" className={css.padding}>
        API DETAIL
      </Typography>

      <Grid item container alignItems="center" className={css.panelDefault}>
        {isLoadingApiDataDetail ? (
          <div className={css.skeletons}>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="circular" width={20} height={20} />
          </div>
        ) : (
          <>
            <InfoTooltip
              typography
              title="This information applies to all projects. The download is available when no project has this option"
            >
              <Typography variant="h6" className={css.heading}>
                General information
              </Typography>
            </InfoTooltip>
            <DownloadButton />
          </>
        )}
      </Grid>

      <ApiDataView data={apiDetail} loading={isLoadingApiDataDetail} />

      <Typography color="primary" className={css.padding}>
        API TESTER
      </Typography>

      <ApiTester useCaseKey={useCaseKey} useCaseVersion={useCaseVersion} />
    </Paper>
  );
};

export default PublicApi;
