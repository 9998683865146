import { SolutionKeyParams } from "pages/RoutesTyping";
import { useParams } from "react-router";
import { SolutionKeyContext } from "./SolutionKeyContext";

const SolutionKeyProvider = ({ children }: any) => {
  const { solutionKey } = useParams<SolutionKeyParams>();

  return (
    <SolutionKeyContext.Provider value={solutionKey}>
      {children}
    </SolutionKeyContext.Provider>
  );
};
export default SolutionKeyProvider;
